// @flow

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import type { Node } from 'react'
import { useLocation } from 'react-router-dom'

import { globalModalError } from '../../Layout/Layout.actions'

import { getCurrentFilters } from '../../../utils/routing'

import { MAIL_SEARCH_RESET } from '../../MailSearch/MailSearch.actionTypes'
import {
  checkBlacklist,
  getMailAccountSimpleList,
} from '../../../core/api/api.mail'
import {
  analyzeVariables,
  analyzeVariablesStatus,
} from '../../../core/api/api.emailTemplate'
import { getPrintersList } from '../../../core/api/api.printer'
import {
  analyzeGoogleTemplateVariables,
  getGoogleConnection,
} from '../../../core/api/api.docTemplates'
import {
  getInitialFiles,
  getInitialValues,
  getUsersEmails,
  getAccounts,
} from './NewMailReplyForm.utils'
import SendMessageForm from '../../SendMessageForm'
import Modal from '../../Modal'
import ReplyHeader from './ReplyHeader'
import ReplyFooter from './ReplyFooter'
import ConfirmationPopup from '../../modals/ConfirmationPopup'
import ReplyAdditional from './ReplyAdditional'
import PreviewModal from './PreviewModal'
import AnalyzeModal from './AnalyzeModal'
import PrinterModal from './PrinterModal'

import { TEMPLATE_TYPE } from '../../../constants'
import { hasEmail } from '../../../utils/utils'
import { getHTMLDom } from '../MailMessageFull/Message/Message.utils'
import { useHistory } from 'react-router-dom'
import Lightbox from '../../LightboxGallery'

import {
  NYLAS,
  NYLAS_V3,
} from '../../Menu/filters/MailsCredentialFilter/MailsCredentialFilter.constants'

const ANALYZE_SUCCESS = 'SUCCESS'
const ANALYZE_PENDING = 'PENDING'
const ANALYZE_STARTED = 'STARTED'

const { EMAIL, DOCS } = TEMPLATE_TYPE

type Props = {
  buildingId?: number,
  created?: string,
  creating: boolean,
  externalFiles: Array<Object>,
  ExtraTool?: Node,
  filesHeaderTitle?: string,
  isBCCShown: boolean,
  isCCShown: boolean,
  isMass?: boolean,
  isRequestThread: boolean,
  isSystemIncluding?: boolean,
  isToHided: boolean,
  onDocsEnabled?: boolean => void,
  onGetForwardValues: (string, Object, Array<Object>, string, string) => Object,
  onGetReplyAllValues: (
    string,
    Object,
    Array<Object>,
    string,
    string
  ) => Object,
  onGetReplyValues: (string, Object, Array<Object>, string, string) => Object,
  onlyManualFileRemove?: boolean,
  onSave: Object => void,
  onToggleMass?: boolean => void,
  onUpdate?: Object => void,
  owner_obj: Object,
  removeExternalFile: number => void,
  replyCallback?: (string, Object, Array<Object>, string, ?string) => Object,
  requestId: number,
  resetForm?: () => void,
  showAddButton: boolean,
  threadTitle: string,
  to_objs: Array<Object>,
  uploadFiles?: boolean,
  uuid: string,
  values?: Object,
  withProviders: boolean,
  working?: boolean,
}

const NewMailReplyForm = (props: Props): Node => {
  const {
    showAddButton,
    requestId,
    buildingId,
    owner_obj,
    to_objs,
    isRequestThread,
    filesHeaderTitle,
    uploadFiles,
    onlyManualFileRemove,
    uuid,
    creating,
    created,
    sender_email,
    files: initialFiles,
    values: initialValues,
    isCCShown: initialCCShown,
    isBCCShown: initialBCCShown,
    isToHided: initialToHided,
    withProviders,
    externalFiles,
    ExtraTool,
    threadTitle,
    replyCallback: externalReplyCallback,
    isSystemIncluding,
    isMass,
    onToggleMass,
    isDeny,
    working,
    directoryId,
    requestFlatId,
  } = props

  const { t } = useTranslation('Mail')
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { building_id, flat_id } = getCurrentFilters(location)
  const building = building_id ? +building_id : undefined
  const flatId = flat_id ? +flat_id : undefined
  const [isLoading, setLoading] = useState(true)
  const [isBlur, setBlur] = useState(false)
  const [account, setAccount] = useState(null)
  const [replyTo, setReplyTo] = useState(null)
  const [currentSignature, setCurrentSignature] = useState('')
  const [replyText, setReplyText] = useState('')
  const [accounts, setAccounts] = useState([])
  const [resetModal, setResetModal] = useState(null)
  const [previewModal, setPreviewModal] = useState(false)
  const [analyzePopup, setAnalyzePopup] = useState(null)
  const [analyzeModal, setAnalyzeModal] = useState(false)
  const [printerModal, setPrinterModal] = useState(null)
  const [isCCShown, setCCShown] = useState(initialCCShown)
  const [isBCCShown, setBCCShown] = useState(initialBCCShown)
  const [isToHided, setToHided] = useState(initialToHided)
  const [editorRef, setEditorRef] = useState(null)
  const [activeIndex, setActiveIndex] = useState(null)
  const [canAccessGoogle, setAccessGoogle] = useState(false)

  const [values, setValues] = useState(
    getInitialValues(initialValues, threadTitle)
  )
  const [isReplyActionBarShown, setReplyActionBarShown] = useState(true)
  const [replyCallback, setReplyCallback] = useState(null)
  const [files, setFiles] = useState(getInitialFiles(values, initialFiles))
  const [filesAsLinks, setFilesAsLinks] = useState(false)
  const [templateId, setTemplateId] = useState(null)
  const [docTemplateId, setDocTemplateId] = useState(null)
  const [authorId, setAuthorId] = useState(null)
  const [saveOriginFileIds, setOriginFileIds] = useState(
    getInitialFiles(values, initialFiles).map(f => f.id)
  )
  const [variablesReport, setVariablesReport] = useState(null)
  const [extVars, setExtVars] = useState(null)
  const [analyzeType, setAnalyzeType] = useState(EMAIL)
  const [proceedEditExt, setProceedEditExt] = useState(false)

  const [docFoldersStatus, setDocFoldersStatus] = useState([])
  const [docsEnabled, setDocsEnabled] = useState(false)

  const [printers, setPrinters] = useState([])

  const [isDisabledPromt, setIsDisabledPromt] = useState(false)

  const { to, title, message, cc, bcc } = values

  const [blacklist, setBlacklist] = useState([])

  useEffect(() => {
    setCCShown(cc.length > 0)
    setBCCShown(bcc.length > 0)
  }, [cc, bcc])

  const generateEmailMessage = () => {
    if (!account || !editorRef) {
      return
    }

    const { insert_signature_before_quoted_text, signature } = account

    let text = values.message

    let processedValues = null

    if (replyCallback && !values.message) {
      processedValues = replyCallback(
        uuid,
        owner_obj,
        to_objs,
        threadTitle,
        created,
        sender_email
      )
    }

    let reply_text = replyText

    if (processedValues && !reply_text) {
      reply_text = `<div id="reply">${processedValues.message || ''}</div>`
      setReplyText(reply_text)
    }

    let breakLine = currentSignature ? '' : '<br>'

    let currentAccSignature = `<div id="${
      'sign' + account.id
    }">${signature}</div>`

    if (currentSignature) {
      let signParser = new DOMParser().parseFromString(
        currentSignature,
        'text/html'
      )
      let textParser = new DOMParser().parseFromString(text, 'text/html')
      let id = signParser.getElementsByTagName('body')[0].firstChild.id
      let signInText = textParser.getElementById(id)
      signInText && signInText.remove()
      text = textParser.body.innerHTML
    }

    if (replyText && !signature) {
      setValues({ ...values, message: text })

      return
    }

    if (replyText) {
      let txtParser = new DOMParser().parseFromString(text, 'text/html')
      let replyEl = txtParser.getElementById('reply')
      replyEl && replyEl.remove()
      text = txtParser.body.innerHTML
    }

    if (currentAccSignature && text.indexOf(currentAccSignature) === -1) {
      if (insert_signature_before_quoted_text && uuid !== 'create') {
        text = `${text}${breakLine}${currentAccSignature}${reply_text}`
      } else {
        text = `${text}${
          !replyText ? '<p></p>' : ''
        }${reply_text}${breakLine}${currentAccSignature}${breakLine}`
      }

      setCurrentSignature(currentAccSignature)
    }

    if (processedValues) {
      if (processedValues.files) {
        setFiles(processedValues.files)
        setOriginFileIds(processedValues.files.map(f => f.id))
      } else {
        setOriginFileIds([])
      }

      setValues({ ...processedValues, message: text })
    } else {
      setValues({ ...values, message: text })
    }

    if (uuid !== 'create') {
      editorRef.focus()
      editorRef.getContainer().scrollIntoView()
    }
  }

  useEffect(() => {
    setLoading(true)
    setAccounts([])

    if (docTemplateId) {
      handleDeleteDoc()
    }
  }, [isMass])

  const formatOutgoingAccoint = a => ({
    label: a.email_from_name || a.email_from,
    value: a.id,
    ...a,
  })

  useEffect(() => {
    if (isLoading && accounts.length === 0) {
      getMailAccountSimpleList({
        is_active: true,
        service: isSystemIncluding && !isMass ? true : undefined,
        info: isMass ? true : undefined,
      })
        .then(data => {
          setAccessGoogle(data?.permissions?.can_access_google_folders)

          const outgoingAccounts = [...getAccounts(data)]

          setAccounts(outgoingAccounts)

          if (outgoingAccounts && outgoingAccounts.length) {
            let [outgoingAccount] = outgoingAccounts.filter(a => !a.is_info)

            if (!outgoingAccount) {
              outgoingAccount = outgoingAccounts[0]
            } else {
              setReplyTo(formatOutgoingAccoint(outgoingAccount))
            }

            setAccount(formatOutgoingAccoint(outgoingAccount))
          }
        })
        .finally(() => setLoading(false))
    }
  }, [isLoading])

  const checkDocsFolderStatus = folder_type => {
    const [folder] = docFoldersStatus.filter(f => f.folder_type === folder_type)

    return folder?.status === 'ok' || folder?.status === 'file_size_exceeded'
  }

  useEffect(() => {
    if (isLoading) {
      return
    }

    if (canAccessGoogle) {
      getGoogleConnection().then(data => {
        if (data.status === 'ok') {
          setDocFoldersStatus(data.folders)
        }
      })
    }
  }, [isLoading, canAccessGoogle])

  useEffect(() => {
    getPrintersList().then(data => {
      if (Array.isArray(data.results?.objects)) {
        setPrinters(data.results?.objects)
      }
    })
  }, [])

  useEffect(() => {
    const folder_type = isMass ? 'mass_emailing' : 'request'
    setDocsEnabled(checkDocsFolderStatus(folder_type))
  }, [isMass, docFoldersStatus])

  useEffect(() => {
    if (props.onDocsEnabled) {
      props.onDocsEnabled(docsEnabled)
    }
  }, [docsEnabled])

  useEffect(() => {
    if (props.onUpdate) {
      props.onUpdate({ ...values, files })
    }
  }, [values, files])

  useEffect(() => {
    if (
      (creating || uuid === 'create' || replyCallback) &&
      account &&
      editorRef
    ) {
      generateEmailMessage()
    }
  }, [creating, uuid, account, editorRef, replyCallback])

  useEffect(() => {
    if (externalReplyCallback) {
      setReplyCallback(() => externalReplyCallback)
    }
  }, [externalReplyCallback])

  const hideModal = () => setResetModal(null)

  const hidePreviewModal = () => setPreviewModal(false)

  const resetValues = () => {
    if (props.resetForm) {
      props.resetForm()
    }

    dispatch({ type: MAIL_SEARCH_RESET, key: `${uuid}to` })
    dispatch({ type: MAIL_SEARCH_RESET, key: `${uuid}bcc` })
    dispatch({ type: MAIL_SEARCH_RESET, key: `${uuid}cc` })
    setToHided(props.resetForm ? true : false)
    setBCCShown(false)
    setCCShown(false)
    setReplyActionBarShown(true)
    setReplyCallback(null)
    setFiles(getInitialFiles(values, initialFiles))

    if ((creating || uuid === 'create') && account) {
      const { signature } = account

      setValues(
        getInitialValues(
          initialValues,
          threadTitle,
          `<p> </p>\n<div id="${'sign' + account.id}">${signature}</div>`
        )
      )
    } else {
      setValues(getInitialValues(initialValues, threadTitle))
    }

    hideModal()

    if (props.onClose) {
      props.onClose()
    }
  }

  const deleteEmail = i => {
    to.splice(i, 1)
    updateValue('to', to)
  }

  const showModal = () => {
    setResetModal(
      <ConfirmationPopup
        confirm={t('ClearButton')}
        cancel={t('CancelButton')}
        title={t('ResetMailTitle')}
        text={t('ConfirmMailReset')}
        onClose={hideModal}
        onOk={resetValues}
      />
    )
  }

  const showPreviewModal = id => {
    setTemplateId(id)
    setPreviewModal(true)
  }

  const handleAnalyze = ({
    author_id,
    id,
    templateType,
    force = false,
    externalVars,
  }) => {
    setAuthorId(author_id)
    setAnalyzeType(templateType)
    setProceedEditExt(false)

    if (templateType === EMAIL) {
      setTemplateId(id)
    }

    if (templateType === DOCS) {
      setDocTemplateId(id)

      if (externalVars) {
        setExtVars(externalVars)
      }
    }

    if (to.length === 0) {
      return
    }

    setLoading(true)

    const params = {
      dependencies: {
        author_id,
        request_id: requestId || undefined,
        building_id: building,
        flat_id: flatId,
      },
      recipients: to.map(u => u.profile?.id || u.name),
      text: templateType === EMAIL ? message : undefined,
      template: templateType === DOCS ? id : undefined,
    }

    const analyzeApi =
      templateType === DOCS ? analyzeGoogleTemplateVariables : analyzeVariables
    const args = templateType === DOCS ? [params] : [id, params]

    analyzeApi(...args).then(data => {
      if (data.eventId) {
        analyzeStatus(data.eventId, force, templateType, externalVars)
      }
    })
  }

  const analyzeStatus = (eventId, force, templateType, externalVars) => {
    analyzeVariablesStatus(eventId).then(data => {
      if (data.status === ANALYZE_PENDING || data.status === ANALYZE_STARTED) {
        setTimeout(() => {
          analyzeStatus(eventId, force, templateType, externalVars)
        }, 1000)

        return
      }

      if (data.status === ANALYZE_SUCCESS) {
        setLoading(false)

        let localReport = []
        const ext_vars = externalVars || extVars

        if (templateType === DOCS && ext_vars) {
          localReport = Object.keys(ext_vars)
            .filter(v => ext_vars[v].length === 0)
            .map(v => ({ variable: v, external: true }))
        }

        if (data.result.length > 0 || localReport.length > 0) {
          setVariablesReport([...data.result, ...localReport])
          setAnalyzeModal(true)
        } else if (force) {
          showAnalyzePopup()
        }
      }
    })
  }

  const handleRemoveDocs = () => {
    setExtVars(null)
    setDocTemplateId(null)
  }

  const handleDeleteEmails = recipients => {
    updateValue('to', recipients)
  }

  const handleDeleteDoc = () => {
    setFiles(files.filter(f => f.id !== docTemplateId))
  }

  const handleEditExternal = () => {
    setProceedEditExt(true)
  }

  const showAnalyzePopup = () => {
    setAnalyzePopup(
      <ConfirmationPopup
        confirm={t('OkButton')}
        title={t('EmptyVariablesNotFound')}
        text={false}
        showCancel={false}
        onClose={hideAnalyzePopup}
        onOk={hideAnalyzePopup}
      />
    )
  }

  const hideAnalyzePopup = () => setAnalyzePopup(null)
  const hideAnalyzeModal = () => setAnalyzeModal(false)

  const handelShowCC = () => setCCShown(true)
  const handelShowBCC = () => setBCCShown(true)

  const updateValue = (key, value) => {
    setValues(values => ({ ...values, [key]: value }))
  }

  const renderHeader = () => {
    return (
      <ReplyHeader
        requestFlatId={requestFlatId}
        requestBuildingId={buildingId}
        setValues={setValues}
        withProviders={withProviders}
        isMass={isMass}
        uuid={uuid}
        values={values}
        blacklist={blacklist}
        isCCShown={isCCShown}
        isBCCShown={isBCCShown}
        isRequestThread={isRequestThread}
        isToHided={isToHided}
        account={account}
        printers={printers}
        replyTo={replyTo}
        accounts={accounts}
        updateValue={updateValue}
        setBlur={setBlur}
        working={isLoading}
        docsEnabled={docsEnabled}
        files={files}
        proceedExt={proceedEditExt}
        isDeny={isDeny}
        onUpdateFiles={setFiles}
        onAnalyzeDocs={handleAnalyze}
        onRemoveDocs={handleRemoveDocs}
        onToggleMass={onToggleMass}
        onShowCC={handelShowCC}
        onShowBCC={handelShowBCC}
        onChangeAccount={setAccount}
        onChangeReplyTo={setReplyTo}
      />
    )
  }

  const renderFooter = () => {
    return (
      <ReplyFooter
        creating={creating}
        uuid={uuid}
        isReplyActionBarShown={isReplyActionBarShown}
        onSetReplyCb={setReplyCallback}
        onGetReplyValues={props.onGetReplyValues}
        onGetReplyAllValues={props.onGetReplyAllValues}
        onGetForwardValues={props.onGetForwardValues}
      />
    )
  }

  const renderAdditional = () => {
    return (
      <ReplyAdditional
        accounts={accounts.filter(a => !a.is_info)}
        working={isLoading}
      />
    )
  }

  const handleFocusEditor = () => {
    if (uuid === 'create' || creating) {
      return
    }

    setToHided(false)
    setReplyActionBarShown(false)
  }

  const quillProps = {
    value: values.message,
    onChange: value => updateValue('message', value),
    onFocus: handleFocusEditor,
    setEditorRef,
  }

  const handleSend = () => {
    setLoading(true)
    checkBlacklist({
      emails: [...values.to, ...values.cc, ...values.bcc]
        .filter(u => hasEmail(u))
        .map(v => v.profile?.email || v.name),
    }).then(data => {
      if (data.length > 0) {
        setBlacklist(data)
        setLoading(false)
        dispatch(globalModalError(t('BlackListEmailText'), t('BlacklistEmail')))
      } else {
        handleSave()
      }
    })
  }

  const handleSave = () => {
    if (props.onSave) {
      let message = values.message
      const regExp = /(<p+)(.*?)(style=")(.*?)(;")(.*?)(>?)/g

      message = values.message.replace(regExp, '$1$2$3$4; margin: 0px$5$6$7')

      const htmlDom = getHTMLDom(message)

      Array.from(htmlDom.querySelectorAll('p.MsoNormal')).forEach(
        item => (item.style.margin = '0px')
      )

      message = htmlDom.querySelector('body').innerHTML

      const params = {
        title: values.title,
        text: message,
        files: files.filter(f => !f.doc).map(f => f.id),
        to: getUsersEmails(values.to),
        cc: getUsersEmails(values.cc),
        bcc: getUsersEmails(values.bcc),
        credentials: undefined,
        account: undefined,
        files_as_links: filesAsLinks,
        separate_messages: isMass,
        documents_templates: undefined,
        printer: values.printer?.id || undefined,
      }

      if (docTemplateId) {
        params.documents_templates = files
          .filter(f => f.doc)
          .map(f => ({
            template: f.id,
            dependencies: { author_id: authorId },
            external_variables: extVars || undefined,
          }))
      }

      setFiles([])

      if (account) {
        account.account_type === NYLAS || account.account_type === NYLAS_V3
          ? (params.account = account.value)
          : (params.credentials = account.value)
      }

      if ((params.credentials === 0 || params.account === 0) && replyTo) {
        params.reply_to = replyTo.value
        params.reply_to_nylas =
          replyTo.account_type === NYLAS || replyTo.account_type === NYLAS_V3
            ? true
            : undefined
      }

      if (isMass && (authorId || building || flatId) && templateId) {
        params.snippet = templateId
        params.snippet_dependencies = Object.assign(
          {},
          authorId ? { author_id: authorId } : {},
          building ? { building_id: building } : {},
          flatId ? { flat_id: flatId } : {}
        )
      }

      if (params.printer) {
        getPrintersList().then(data => {
          const res = data.results?.objects

          if (Array.isArray(res)) {
            const [printerId] = res.filter(p => p.id === params.printer)

            if (printerId) {
              props.onSave(params)
            } else {
              setPrinters(res)
              setPrinterModal(
                <PrinterModal
                  mode='error'
                  printers={res}
                  isOpen={true}
                  onClose={() => setPrinterModal(null)}
                  onSelectPrinter={p => updateValue('printer', p)}
                />
              )
            }
          }
        })
      } else {
        props.onSave(params)
      }
    }

    setIsDisabledPromt(true)
  }

  const usersCountWithEmail = to.filter(u => hasEmail(u)).length
  const blacklistUsers = to.some(u => u.blacklist)

  const validate = () => {
    const { title, message } = values

    const validatedTitle =
      uuid === 'create' || creating ? title.trim() !== '' : true
    const messageRegex = /(<([^>]+)>)/gi

    return !(
      message.replace(messageRegex, '').trim() !== '' &&
      usersCountWithEmail !== 0 &&
      validatedTitle &&
      !blacklistUsers
    )
  }

  const handleBlur = () => {
    if (!isBlur) {
      setBlur(true)
    }
  }

  const closeLightbox = () => {
    setActiveIndex(null)
  }

  return (
    <>
      <div className='reply-container'>
        <SendMessageForm
          isEmail
          isDisabledPromt={isDisabledPromt}
          setSelectedFiles={props.setSelectedFiles}
          account={account}
          showAddButton={showAddButton}
          requestId={requestId}
          buildingId={building}
          flatId={requestFlatId}
          recipientId={to.length === 1 ? to[0].profile?.id : undefined}
          templateParams={{ for_building: buildingId }}
          isRequestThread={isRequestThread}
          filesHeaderTitle={filesHeaderTitle}
          uploadFiles={uploadFiles}
          canSubmit={!validate()}
          ExtraTool={ExtraTool}
          files={files.concat(externalFiles)}
          filesList={files}
          filesAsLinks={filesAsLinks}
          removeExternalFile={props.removeExternalFile}
          quillProps={quillProps}
          resetValues={showModal}
          onlyManualFileRemove={onlyManualFileRemove}
          renderAdditional={renderAdditional}
          renderHeader={renderHeader}
          renderFooter={renderFooter}
          saveOriginFileIds={saveOriginFileIds}
          toolbarId={`toolbar-${uuid}`}
          working={isLoading || working}
          editorDisabled={!accounts || !accounts.filter(a => !a.is_info).length}
          count={usersCountWithEmail}
          isMass={isMass}
          isModal={props.isModal}
          showCanLeave={props.showCanLeave}
          docTemplateId={docTemplateId}
          replyText={replyText}
          uuid={uuid}
          directoryId={directoryId}
          proceedExt={proceedEditExt}
          history={history}
          values={values}
          isChangedValues={props.changedValues || false}
          setChangedValues={props.isModal && props.setChangedValues}
          setShowCanLeave={props.setShowCanLeave}
          setActiveIndex={i => setActiveIndex(i)}
          onAnalyzeDocs={handleAnalyze}
          onToggleMass={onToggleMass}
          onSubmit={
            props.onSave && accounts && accounts.filter(a => !a.is_info).length
              ? handleSend
              : null
          }
          onUpdateFiles={setFiles}
          onUpdateTitle={newTitle => updateValue('title', newTitle)}
          onUpdateFilesAsLinks={setFilesAsLinks}
          onBlur={handleBlur}
          onPreview={showPreviewModal}
          onAnalyze={handleAnalyze}
          onRemoveDocs={handleRemoveDocs}
          onReplaceTemplate={generateEmailMessage}
          onClose={props.onClose}
        />
        <Modal isOpen={!!resetModal} onRequestClose={hideModal}>
          {resetModal}
        </Modal>
        <Modal isOpen={!!analyzePopup} onRequestClose={hideAnalyzePopup}>
          {analyzePopup}
        </Modal>
        {printerModal && (
          <Modal
            contentLabel=''
            className='Modal__Bootstrap modal-dialog'
            isOpen={!!printerModal}
            onRequestClose={() => setPrinterModal(null)}
          >
            {printerModal}
          </Modal>
        )}
        {previewModal && (
          <PreviewModal
            isOpen={previewModal}
            templateId={templateId}
            from={account.email_from}
            recipients={to}
            cc={cc}
            bcc={bcc}
            requestId={requestId}
            authorId={authorId}
            building_id={building}
            flat_id={flatId}
            subject={title}
            text={message}
            externalVars={extVars}
            files={files.concat(externalFiles)}
            filesAsLinks={filesAsLinks}
            onClose={hidePreviewModal}
            onDeleteEmail={deleteEmail}
            onSend={handleSend}
          />
        )}
        {analyzeModal && (
          <AnalyzeModal
            isOpen={analyzeModal}
            recipients={to}
            report={variablesReport}
            templateType={analyzeType}
            onClose={hideAnalyzeModal}
            onDeleteEmails={handleDeleteEmails}
            onDeleteDoc={handleDeleteDoc}
            onEditExternal={handleEditExternal}
          />
        )}
      </div>
      <Lightbox
        newGallery
        activeIndex={activeIndex}
        images={files.concat(externalFiles)}
        scope='tree'
        object={requestId}
        onClose={closeLightbox}
      />
    </>
  )
}

NewMailReplyForm.defaultProps = {
  externalFiles: [],
  isCCShown: false,
  isBCCShown: false,
  isToHided: false,
}

export default NewMailReplyForm
