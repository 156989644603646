// @flow

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { get, map } from 'lodash-es'

import { REQUEST_VIEW_UPDATE_THREAD_COUNTERS } from '../../../containers/RequestView/RequestView.constants'
import {
  deleteMessage,
  getRequest,
  getThreadMessage,
  getRequestThreadInfo,
  readThread,
} from '../../../core/api/api.request'
import { SimpleBlock, Header } from '../../Block'
import Nbsp from '../../NBSP'
import Loader from '../../Loader'
import Modal from '../../Modal'
import Icon from '../../Icon'
import ConfirmationPopup from '../../modals/ConfirmationPopup'
import Message from './Message'
import PrintMailThreadPreview from '../../../containers/Mail/MailThread/PrintMailThreadPreview'
import { useUser } from '../../../hooks/useUser'
import { resendRequestMessage } from '../../../core/api/api.mail'
import { globalModalError, serverError } from '../../Layout/Layout.actions'
import ConvertToRequestPopup from '../../modals/ConvertToRequestPopup'
import AttachToRequestPopup from '../../modals/AttachToRequestPopup'
import NewWizard from '../../NewWizard'
import { isWizardEnabled } from '../../../utils/commonSelectors'
import { isDwellerUser } from '../../../utils/utils'

const EmailThread = props => {
  const {
    audience,
    subject,
    isDeny,
    print,
    directoryId,
    loadThreads,
    buildingId,
    scope = 'request',
    object,
    flatId,
    outbound,
  } = props

  const params = useParams()
  const uuid = print ? params.uuid : props.uuid
  const requestId = print ? params.requestId : props.requestId
  const muuid = params.muuid

  const { t } = useTranslation('Request')

  const [messages, setMessages] = useState([])
  const [loading, setLoading] = useState(true)
  const [pending, setPending] = useState(false)
  const [requestLoading, setRequestLoading] = useState(true)
  const [selectedMessageId, setSelectedMessageId] = useState(null)
  const [selectedFiles, setSelectedFiles] = useState([])
  const [modal, setModal] = useState(null)
  const [requestThreadTitle, setRequestThreadTitle] = useState(null)
  const [requestThreadSubject, setRequestThreadSubject] = useState(null)

  const [wizardId, setWizardId] = useState(null)

  const user = useUser()

  const dispatch = useDispatch()
  const isEnabled = useSelector(isWizardEnabled)

  useEffect(() => {
    getRequestThreadInfo(requestId, uuid).then(({ title }) => {
      if (title) {
        setRequestThreadSubject(title)
      }
    })
  }, [print])

  useEffect(() => {
    if (loading) {
      getThreadMessage(requestId, { request_thread: uuid, page_size: 100500 })
        .then(data => {
          setMessages(data.results.objects)
          readThread(requestId, uuid)
            .then(() => getRequest(requestId))
            .then(data => {
              const {
                manager_thread_read,
                provider_thread_read,
                dweller_thread_read,
                request_no,
                title,
              } = data

              setRequestThreadTitle(
                `${t('Ticket')} ${
                  request_no ? ` No.${request_no}` : `${title}`
                }`
              )
              setRequestLoading(false)

              dispatch({
                type: REQUEST_VIEW_UPDATE_THREAD_COUNTERS,
                counters: {
                  manager_thread_read,
                  provider_thread_read,
                  dweller_thread_read,
                },
              })
            })
        })
        .finally(() => setLoading(false))
    }
  }, [uuid, loading])

  const hideModal = () => setModal(null)

  const removeExternalFile = id =>
    setSelectedFiles(
      selectedFiles.filter(selectedFile => selectedFile.id !== id)
    )

  const handleSelectFiles = files => {
    const selectedIds = map(selectedFiles, 'id')

    const newFiles = files.filter(file => !selectedIds.includes(file.id))

    setSelectedFiles(selectedFiles.concat(newFiles))
  }

  const handleDelete = uuid => {
    deleteMessage(requestId, uuid).then(() => {
      setLoading(true)
      hideModal()
      props.setUuid?.(null)
      loadThreads?.()
    })
  }

  const handleClearFiles = () => {
    setSelectedFiles([])
  }

  const handleOpenDelete = uuid => {
    const modal = (
      <ConfirmationPopup
        title={t('DeleteMessageTitle')}
        text={t('ConfirmActionDeleteText')}
        confirm={t('Delete')}
        cancel={t('Cancel')}
        onClose={hideModal}
        onOk={() => handleDelete(uuid)}
      />
    )
    setModal(modal)
  }

  const handleOpenConvert = id => {
    if (isEnabled) {
      setWizardId(id)
    } else {
      const modal = (
        <ConvertToRequestPopup
          emailMessageId={id}
          onClose={hideModal}
          onReload={() => setLoading(true)}
        />
      )
      setModal(modal)
    }
  }

  const handleAttachToRequest = () => {
    hideModal()
    setLoading(true)
  }

  const handleOpenAttach = id => {
    const message = messages.find(m => m.uuid === id)
    const { linked_requests } = message
    const requestIds = [
      ...(linked_requests ? linked_requests.map(r => r.id) : []),
      requestId,
    ].join(',')
    const modal = (
      <AttachToRequestPopup
        emailMessageId={id}
        requestIds={requestIds}
        onClose={hideModal}
        onSave={handleAttachToRequest}
      />
    )
    setModal(modal)
  }

  const handleCloseWizard = () => setWizardId(null)

  const handleResend = uuid => {
    resendRequestMessage(uuid)
      .then(() => {
        setLoading(true)
      })
      .catch(error => {
        const status = get(error, ['message', 'response', 'status'], null)
        const errorText = get(
          error,
          ['message', 'response', 'data', 'errors'],
          null
        )

        if (status === 400) {
          dispatch(globalModalError(errorText, '', true))
        } else if (status) {
          dispatch(serverError(error))
        }
      })
  }

  const handlePrintAll = () => {
    window.open(`/request/${requestId}/emails/${uuid}/print`)
  }

  if (loading || (print && requestLoading)) {
    return <Loader type='big' text={false} />
  }

  const messageCount = messages.length
  const selectedMessage =
    selectedMessageId &&
    Array.isArray(messages) &&
    messages.find(m => m.uuid === selectedMessageId)

  return print ? (
    <PrintMailThreadPreview
      messageCount={messageCount}
      messages={messages}
      title={requestThreadTitle}
      threadSubject={requestThreadSubject}
      messageUuid={muuid}
      working={requestLoading}
    />
  ) : (
    <div className='mail-thread'>
      <SimpleBlock style={{ border: 0, marginTop: 0 }}>
        <Header header textTitle={subject}>
          {subject}
          <Nbsp />
          {messageCount > 1 && (
            <span className='unit__title-num message-counter'>
              {messageCount}
            </span>
          )}
          <span extra className='unit__title-print' onClick={handlePrintAll}>
            {t('PrintAll')}
            <Icon id='printer' />
          </span>
        </Header>
        <div className='messages'>
          {messages.map(message => (
            <Message
              flatId={flatId}
              object={object}
              scope={scope}
              isDeny={isDeny}
              setSelectedFiles={handleSelectFiles}
              selectedFiles={selectedFiles}
              clearSelectedFiles={handleClearFiles}
              requestId={requestId}
              buildingId={buildingId}
              message={message}
              outbound={outbound}
              pending={pending}
              user={user}
              setUuid={props.setUuid}
              setSubject={props.setSubject}
              uuid={uuid}
              subject={subject}
              audience={audience}
              removeExternalFile={removeExternalFile}
              key={message.uuid}
              messages={messages}
              setLoading={setLoading}
              setPending={setPending}
              setSelectedMessageId={setSelectedMessageId}
              selectedMessageId={selectedMessageId}
              directoryId={directoryId}
              changedValues={props.changedValues}
              setChangedValues={props.setChangedValues}
              externalFiles={selectedFiles.map(file => ({
                ...file,
                isExternalFile: true,
              }))}
              onDelete={handleOpenDelete}
              onResend={handleResend}
              onConvert={handleOpenConvert}
              onAttach={handleOpenAttach}
            />
          ))}
        </div>
      </SimpleBlock>
      {!!modal && (
        <Modal isOpen onRequestClose={hideModal}>
          {modal}
        </Modal>
      )}
      {wizardId && (
        <NewWizard
          messageUuid={wizardId}
          init={{
            user:
              selectedMessage && isDwellerUser(selectedMessage.owner_obj)
                ? selectedMessage.owner_obj.id
                : null,
          }}
          onClose={handleCloseWizard}
          onReload={() => setLoading(true)}
        />
      )}
    </div>
  )
}

export default EmailThread
