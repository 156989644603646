// @flow

import React from 'react'
import { useEffect, useState } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { partial } from 'lodash-es'
import type { Node } from 'react'

import Button from '../../../components/Button'
import Modal from '../../../components/Modal'
import ModalBody from '../../../components/Modal/ModalBody'
import ModalCloseButton from '../../../components/Modal/ModalCloseButton'
import ModalHead from '../../../components/Modal/ModalHead'
import ModalButtons from '../../../components/Modal/ModalButtons'
import NewTable from '../../../components/NewTable'
import NewTableHeader from '../../../components/NewTable/NewTableHeader'
import NewTableHeaderRow from '../../../components/NewTable/NewTableHeaderRow'
import NewTableHeaderCell from '../../../components/NewTable/NewTableHeaderCell'
import NewTableBody from '../../../components/NewTable/NewTableBody'
import NewTableBodyRow from '../../../components/NewTable/NewTableBodyRow'
import NewTableBodyCell from '../../../components/NewTable/NewTableBodyCell'
import Checkbox from '../../../components/Checkbox'
import { useOverflow, useSelected } from '../../../hooks'
import {
  getActivityFiles,
  getPhaseFiles,
  updateActivity,
  updatePhase,
} from '../../../core/api/api.pipeline'
import Text from '../../../components/NewTable/NewTableHeaderCell/Text'
import ConfirmationPopup from '../../../components/modals/ConfirmationPopup'

import styles from './ThreadModal/ThreadModal.module.scss'
import Lightbox from '../../../components/LightboxGallery'

const ActivityFileModal = (props): Node => {
  const {
    requestId,
    data: { phaseId, activityId },
    outbound,
  } = props

  const [files, setFiles] = useState(null)
  const [isLoading, setLoading] = useState(true)
  const [isConfirmOpen, setConfirmOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState(null)

  const { t } = useTranslation('Activities')

  useOverflow()

  const [selectedItems, changeSelected, setSelected, isAllSelected] =
    useSelected()

  useEffect(() => {
    if (phaseId) {
      getPhaseFiles(phaseId).then(setFiles)
    }

    if (activityId) {
      getActivityFiles(activityId).then(setFiles)
    }
  }, [phaseId, activityId])

  useEffect(() => {
    if (files && files.length) {
      setLoading(false)
    }

    if (isConfirmOpen) {
      setConfirmOpen(false)
    }
  }, [files])

  if (!files) {
    return null
  }

  const handleChange = e => {
    changeSelected(parseInt(e.currentTarget.value))
  }

  const handleChangeAll = () => {
    if (isAllSelected(files)) {
      setSelected([])
    } else {
      setSelected(files.map(file => file.id))
    }
  }

  const handleCancel = () => {
    if (selectedItems.length) {
      setSelected([])
    } else {
      props.onClose()
    }
  }

  const handleSave = () => {
    setLoading(true)

    const newFileIds = files
      .map(file => file.id)
      .filter(id => !selectedItems.includes(id))

    let api = partial(updateActivity, activityId)

    if (phaseId) {
      api = partial(updatePhase, phaseId)
    }

    api({ files: newFileIds }).then(data => {
      const newFiles = data?.files

      if (newFiles && newFiles.length) {
        setSelected([])
        setFiles(files.filter(f => !selectedItems.includes(f.id)))
      } else {
        props.onClose()
      }

      props.setReloading(true)
    })
  }

  const tableClass = classnames({
    'working-overlay': isLoading,
  })

  const handleCloseModal = () => {
    setConfirmOpen(false)
  }

  const handleOpenConfirm = () => {
    setConfirmOpen(true)
  }

  const handleClick = file => {
    let activeIndex = files.findIndex(f => f.id === file.id)
    setActiveIndex(activeIndex)
  }

  const showActions = !outbound && files.some(f => f.permissions?.can_delete)

  const closeLightbox = () => {
    setActiveIndex(null)
  }

  return (
    <Modal
      isOpen
      style={{
        content: { minWidth: '732px', maxWidth: '732px' },
      }}
    >
      <ModalHead title={t('FilesBreakingTitle')} />
      <ModalCloseButton onClose={props.onClose} />
      <ModalBody style={{ maxHeight: '604px', overflow: 'auto' }}>
        <NewTable className={tableClass}>
          <NewTableHeader>
            <NewTableHeaderRow>
              <NewTableHeaderCell style={{ width: '50%' }}>
                {showActions && (
                  <Checkbox
                    outer
                    disabled={!files.length || isLoading}
                    checked={isAllSelected(files)}
                    selected={!!selectedItems.length && !isAllSelected(files)}
                    onChange={handleChangeAll}
                  />
                )}
                <Text title={t('ActivityFileName')} />
              </NewTableHeaderCell>
              {phaseId && (
                <NewTableHeaderCell
                  style={{ width: '50%' }}
                  title={t('FileActivities')}
                />
              )}
            </NewTableHeaderRow>
          </NewTableHeader>
          <NewTableBody>
            {files.map(file => (
              <NewTableBodyRow key={file.id} onClick={() => handleClick(file)}>
                <NewTableBodyCell title={file.filename}>
                  {showActions && (
                    <Checkbox
                      value={file.id}
                      checked={selectedItems.includes(file.id)}
                      className={styles.checkbox}
                      onChange={handleChange}
                    />
                  )}
                </NewTableBodyCell>
                {phaseId && (
                  <NewTableBodyCell
                    title={file.activities
                      .map(activity => activity.title)
                      .join(', ')}
                  />
                )}
              </NewTableBodyRow>
            ))}
          </NewTableBody>
        </NewTable>
      </ModalBody>
      <ModalButtons>
        {showActions && (
          <Button.Save
            disabled={!selectedItems.length || isLoading}
            onClick={handleOpenConfirm}
          >
            {t('BreakLinkedFilesButton')}
          </Button.Save>
        )}
        <Button.Cancel disabled={isLoading} onClick={handleCancel}>
          {t('Common:Cancel')}
        </Button.Cancel>
      </ModalButtons>
      {isConfirmOpen && (
        <Modal isOpen>
          <ConfirmationPopup
            confirm={t('BreakLinkedFilesConfirmButton')}
            title={t('BreakLinkedFilesTitle')}
            text={t('BreakLinkedFilesText')}
            onClose={handleCloseModal}
            onOk={handleSave}
          />
        </Modal>
      )}
      <Lightbox
        newGallery
        activeIndex={activeIndex}
        images={files}
        object={requestId}
        scope='request_activity'
        onClose={closeLightbox}
      />
    </Modal>
  )
}

export default ActivityFileModal
