// @flow

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { replace } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'
import type { Node } from 'react'

import ConfirmationPopup from '../../../components/modals/ConfirmationPopup'
import ConvertToRequestPopup from '../../../components/modals/ConvertToRequestPopup'
import { Header, SimpleBlock } from '../../../components/Block'
import Nbsp from '../../../components/NBSP'
import Icon from '../../../components/Icon'
import { getLasMessageUuid } from './NewMailThread.utils'
import Message from './Message'
import {
  createPostInThread,
  deletePosts,
  resendMessage,
  restorePost,
} from '../../../core/api/api.mail'

import AttachToRequestPopup from '../../../components/modals/AttachToRequestPopup'
import {
  convertUserToManagerSearchObj,
  getForwardMessage,
  getReplyMessage,
} from '../../../utils/utils'
import { useUser } from '../../../hooks'
import { getUserId } from '../../RequestView/RequestViewInfo/RequestViewInfo.utils'
import {
  globalModalError,
  modalError,
  serverError,
} from '../../../components/Layout/Layout.actions'
import { isWizardEnabled } from '../../../utils/commonSelectors'
import { newLineToBr } from '../../../utils/text'
import { get } from 'lodash-es'
import { GLOBAL_MODAL_ERROR } from '../../../components/Layout/Layout.constants'
import { getCurrentFilters } from '../../../utils/routing'

type Props = {|
  hideModal: () => void,
  isMass: boolean,
  messageCount: ?number,
  messages: Array<Object>,
  onOpenWizard: string => void,
  onToggleMass: boolean => void,
  setLoading: boolean => void,
  setModal: Node => void,
  setNewThreadUuid: string => void,
  setUpdating: boolean => void,
  thread: Object,
|}

const NewMailThreadView = (props: Props): Node => {
  const {
    messageCount,
    messages,
    thread,
    isMass,
    onToggleMass,
    setSelectedOwner,
    isSingle,
  } = props

  const { shared, title, uuid } = thread

  const [selectedMessageUuid, setSelectedMessageUuid] = useState(
    getLasMessageUuid(messages)
  )

  useEffect(() => {
    setSelectedOwner(selectedMessageUuid)
  }, [selectedMessageUuid])

  const user = useUser()
  const { t } = useTranslation('Mail')
  const dispatch = useDispatch()
  const isEnabled = useSelector(isWizardEnabled)

  const lastMessage = messages[messages.length - 1]

  const handleUpdate = params => {
    props.setUpdating(true)

    createPostInThread({
      ...params,
      text: params.text.replace(/\n/g, ''),
      thread: uuid,
    })
      .then(data => {
        props.setUpdating(false)

        if (data?.thread && uuid !== data.thread) {
          props.setNewThreadUuid(data.thread)
        } else {
          props.setLoading(true)
        }
      })
      .catch(error => {
        if (error.message.response.status === 400) {
          dispatch(modalError(error.message.response.data))
        }

        props.setUpdating(false)
      })
  }

  const handleDelete = uuid => {
    props.setUpdating(true)

    deletePosts(uuid)
      .then(() => {
        if (lastMessage && lastMessage.uuid === uuid && messages.length === 1) {
          dispatch(replace('/mails'))

          return
        } else {
          props.setUpdating(false)
          props.setLoading(true)
          props.hideModal()
        }
      })
      .catch(error => {
        const status = get(error, ['message', 'response', 'status'], null)

        if (status === 400 || status === 422) {
          dispatch({
            type: GLOBAL_MODAL_ERROR,
            needTranslate: true,
            errorText: 'EmailIntegrationNylas:NeedReconnect',
          })
        } else if (status) {
          dispatch(serverError(error))
        }

        props.hideModal()
        props.setUpdating(false)
      })
  }

  const handleRestore = uuid => {
    props.setUpdating(true)
    restorePost(uuid, { soft_archived: false })
      .then(() => {
        if (lastMessage && lastMessage.uuid === uuid && messages.length === 1) {
          dispatch(replace('/mails'))

          return
        } else {
          props.setUpdating(false)
          props.setLoading(true)
          props.hideModal()
        }
      })
      .catch(error => {
        const status = get(error, ['message', 'response', 'status'], null)

        if (status === 400 || status === 422) {
          dispatch({
            type: GLOBAL_MODAL_ERROR,
            needTranslate: true,
            errorText: 'EmailIntegrationNylas:NeedReconnect',
          })
        } else if (status) {
          dispatch(serverError(error))
        }

        props.hideModal()
        props.setUpdating(false)
      })
  }

  const handleResend = uuid => {
    props.setUpdating(true)
    resendMessage(uuid)
      .then(() => {
        props.setLoading(true)
        props.setUpdating(false)
      })
      .catch(error => {
        const status = get(error, ['message', 'response', 'status'], null)
        const errorText = get(
          error,
          ['message', 'response', 'data', 'errors'],
          null
        )

        if (status === 400) {
          dispatch(globalModalError(errorText, '', true))
        } else if (status) {
          dispatch(serverError(error))
        }

        props.setUpdating(false)
      })
  }

  const handleOpenDelete = uuid => {
    const modal = (
      <ConfirmationPopup
        title={t('DeleteMessageTitle')}
        text={t('ConfirmActionDeleteText')}
        confirm={t('Delete')}
        cancel={t('Cancel')}
        onClose={props.hideModal}
        onOk={() => handleDelete(uuid)}
      />
    )

    props.setModal(modal)
  }

  const handleOpenRestore = uuid => {
    const modal = (
      <ConfirmationPopup
        title={t('RestoreMessageTitle')}
        text={t('ConfirmActionRestoreText')}
        confirm={t('Restore')}
        cancel={t('Cancel')}
        onClose={props.hideModal}
        onOk={() => handleRestore(uuid)}
      />
    )

    props.setModal(modal)
  }

  const handleOpenConvert = id => {
    if (isEnabled) {
      props.onOpenWizard(id)
    } else {
      const modal = (
        <ConvertToRequestPopup
          emailMessageId={id}
          onClose={props.hideModal}
          onReload={() => props.setLoading(true)}
        />
      )

      props.setModal(modal)
    }
  }

  const handleOpenAttach = (id, linked_requests) => {
    const requestIds = [
      ...(linked_requests ? linked_requests.map(r => r.id) : []),
    ].join(',')

    const modal = (
      <AttachToRequestPopup
        emailMessageId={id}
        requestIds={requestIds}
        onClose={props.hideModal}
      />
    )
    props.setModal(modal)
  }

  const handlePrintAll = () => {
    const { soft_archived, sub_menu } = getCurrentFilters(location)

    const softArchived =
      (soft_archived && JSON.parse(soft_archived)) || sub_menu === 'archive'

    window.open(
      `/mail/${uuid}/print${softArchived ? '?soft_archived=true' : ''}`
    )
  }

  const getForwardValues = (
    uuid,
    owner,
    toList,
    subject,
    created,
    sender_email
  ) => {
    const message: Object = messages.find(m => m.uuid === uuid)

    return {
      cc: [],
      bcc: [],
      to: [],
      message: getForwardMessage(
        message.participants.owner,
        message.participants.to,
        message.participants.cc,
        subject,
        created,
        newLineToBr(message.text),
        t,
        sender_email
      ),
      files: message.files,
      title: subject,
    }
  }

  const getImgString = alt => {
    return `<p>[ ${alt} ]</p>`
  }

  const getQuote = text => {
    return `<blockquote>${text.replace(
      /<img.*?alt=['"](.*?)['"].*?>/g,
      getImgString('$1')
    )}</blockquote>`
  }

  const getReplyValues = (
    uuid,
    owner,
    toList,
    subject,
    created,
    sender_email
  ) => {
    const message: Object = messages.find(m => m.uuid === uuid)
    const owner_obj = message.participants.owner
    let to = []

    if (
      getUserId(user) === message.owner_obj?.id &&
      message.participants.to.length
    ) {
      to = message.participants.to.map(someone =>
        convertUserToManagerSearchObj(someone)
      )
    } else {
      to = [convertUserToManagerSearchObj(owner_obj)]
    }

    return {
      to,
      cc: [],
      bcc: [],
      message: `${getReplyMessage(
        owner_obj,
        created,
        t,
        sender_email
      )}${getQuote(newLineToBr(message.text))}`,
      title: subject,
    }
  }

  const getReplyAllValues = (
    uuid,
    owner,
    toList,
    subject,
    created,
    sender_email
  ) => {
    const message: Object = messages.find(m => m.uuid === uuid)
    const owner_obj = message.participants.owner
    let to = message.owner_obj?.id === getUserId(user) ? [] : [owner_obj]
    to = to.concat(message.participants.to)

    return {
      to: to.map(convertUserToManagerSearchObj),
      bcc: [],
      cc: message.participants.cc.map(convertUserToManagerSearchObj),
      message: `${getReplyMessage(
        owner_obj,
        created,
        t,
        sender_email
      )}${getQuote(newLineToBr(message.text))}`,
      title: subject,
    }
  }

  return (
    <SimpleBlock>
      <Header header>
        {t('MailThreadTitle')}
        <Nbsp />
        {!isSingle && !!messageCount && (
          <span className='unit__title-num message-counter'>
            {messageCount}
          </span>
        )}
        {!isSingle && (
          <span extra className='unit__title-print' onClick={handlePrintAll}>
            {t('PrintAll')}
            <Icon id='printer' />
          </span>
        )}
      </Header>
      <div className='messages'>
        {messages.map(message => (
          <Message
            title={title}
            shared={shared}
            lastMessageUuid={lastMessage.uuid}
            key={message.uuid}
            message={message}
            pending={thread.has_pending_message}
            selectedMessageUuid={selectedMessageUuid}
            getForwardValues={getForwardValues}
            getReplyValues={getReplyValues}
            getReplyAllValues={getReplyAllValues}
            isMass={isMass}
            onClick={setSelectedMessageUuid}
            onUpdate={handleUpdate}
            onDelete={handleOpenDelete}
            onRestore={handleOpenRestore}
            onConvert={handleOpenConvert}
            onAttach={handleOpenAttach}
            onResend={handleResend}
            onToggleMass={onToggleMass}
          />
        ))}
      </div>
    </SimpleBlock>
  )
}

export default NewMailThreadView
